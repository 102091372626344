<template>
  <el-card id="unmark">
    <header>
      <h1>取消标记</h1>
    </header>
    <el-divider></el-divider>
    <main>
      <div class="content">
        <div class="link">
          <el-link
            type="primary"
            href="http://haomashensu.360.cn/index.html"
            target="_blank"
            :underline="false"
            style="font-size: 18px"
            >360手机卫士</el-link
          >
          <span>或 &nbsp;拨打</span> 010-89180702
        </div>

        <div class="link">
          <el-link
            type="primary"
            href="http://www.dianhua.cn/appeal"
            target="_blank"
            :underline="false"
            style="font-size: 18px"
            >电话邦</el-link
          >
          <span>或 &nbsp;拨打</span> 4000618800
        </div>

        <div class="link">
          <el-link
            type="primary"
            href="https://www.teddymobile.cn/numberComplain"
            target="_blank"
            :underline="false"
            style="font-size: 18px"
            >泰迪熊移动</el-link
          >
          <span>或 &nbsp;拨打</span> 4006806000
        </div>

        <div class="link">
          <el-link
            type="primary"
            href="https://yun.m.qq.com/content.html#1"
            target="_blank"
            :underline="false"
            style="font-size: 18px"
            >腾讯手机管家</el-link
          >
          <span>或 &nbsp;拨打</span> 0755-83765566
        </div>

        <div class="link">
          <el-link
            type="primary"
            href="https://haoma.baidu.com/person/complaint"
            target="_blank"
            :underline="false"
            style="font-size: 18px"
            >百度手机卫士</el-link
          >
          <span>或 &nbsp;拨打</span> 400-921-3900
        </div>

        <div class="text">
          <p>阿里钱盾：</p>
          <p>钱盾号码标记申诉渠道现改为手机淘宝安全中心，具体操作流程为：</p>
          <p>1.打开手机淘宝客户端（需要将手机淘宝客户端更新至最新版本）</p>
          <p>2.点击右下角我的淘宝</p>
          <p>3.点击右上角设置（齿轮图标）</p>
          <p>4.点击账户与安全</p>
          <p>5.点击最下方的安全中心（蓝色字体）</p>
          <p>6.点击中间靠右侧的号码申诉</p>
          <p>7.按提示填写相关内容后点击提交即可</p>
        </div>

        <div class="text">
          <p>
            搜狗号码通：发送邮件到：haomatong_service@sogou-inc.com（写明需求）
          </p>
        </div>
      </div>
    </main>
  </el-card>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#unmark {
  header {
    h1 {
      font-weight: normal;
      text-align: center;
    }
  }
  main {
    text-align: center;
    .link {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      span {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .text {
      p {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>